export function transposeArray<T>(items: T[][]) {
    let _items = [];

    for (let c = 0; c < items[0].length; c++) {
        let __items = [];
        for (let r = 0; r < items.length; r++) {
            __items.push(items[r][c]);
        }
        _items.push(__items);
    }

    return _items;
}
