import { useStringParam } from 'hooks/routerHooks';
import { CommonProps } from 'components/common';
import { isGroupValid } from 'features/numerals/utils';
import { DropdownForGroups } from './DropdownForGroups';

export function useGroup(
    defaultGroup: string | undefined = 'Custom'
): [string | undefined, (_: string) => void] {
    const [group, setGroup] = useStringParam('group');
    const _script = isGroupValid(group ?? '') ? group : defaultGroup;
    return [_script, setGroup];
}

export function DropdownForGroupsOnPage(props: CommonProps) {
    const [group, setGroup] = useGroup();
    const _props = { group, setGroup, ...props };
    return <DropdownForGroups {..._props} />;
}
