import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { RootState } from 'stores/store';
import { appSlice } from 'stores/app';

export const useGetTitle = () =>
    useAppSelector((state: RootState) => state.app.title);

export function useSetTitle(title: string) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(appSlice.actions.setTitle(title));
    });
}
