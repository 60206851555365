import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { SankhyaClock } from 'pages/SankhyaClock';
import { AppHeader } from 'components/AppHeader';
import { AppFooter } from 'components/AppFooter';
import { SankhyaBaseMultiples } from 'pages/SankhyaBaseMultiples';
import { AboutPage } from 'pages/About';
import { Sankhya } from 'pages/Sankhya';
import { SankhyaSbs } from 'pages/SankhyaSbs';

import { HomePage } from 'pages/Home';
import { ErrorPage } from 'pages/Error';

const _router = createBrowserRouter([
    {
        Component: Layout,
        children: [
            { path: '/', Component: HomePage },
            { path: '/about', Component: AboutPage },

            { path: '/sankhya/:script?', Component: Sankhya },
            { path: '/sankhya/help', Component: Sankhya },

            { path: '/sankhya-sbs', Component: SankhyaSbs },
            { path: '/sankhya-sbs/help', Component: SankhyaSbs },

            { path: '/sankhya-clock', Component: SankhyaClock },
            { path: '/sankhya-clock/help', Component: SankhyaClock },
            {
                path: '/sankhya-base-multiples',
                Component: SankhyaBaseMultiples,
            },
        ],
        errorElement: <ErrorPage />,
    },
]);

function Layout() {
    return (
        <>
            <AppHeader />
            <Outlet />
            <AppFooter />
        </>
    );
}

export function AllRoutes() {
    return <RouterProvider router={_router} />;
}
