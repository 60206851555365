import { Credits } from 'features/numerals/Credits';

export function AboutSankhyaClock() {
    return <span>The page shows current time in selected Numeral System.</span>;
}

export function Help() {
    return (
        <div
            style={{
                paddingTop: '20px',
                paddingLeft: '20px',
                paddingRight: '5px',
            }}>
            <AboutSankhyaClock />
            <br />
            <br />
            The page does not show the actual time represented by different
            cultures. It displays in the form that we use today in HH:MM:SS. The
            hours, minutes and seconds are displayed in the selected Numeral
            System.
            <br />
            <br />
            <Credits />
        </div>
    );
}
