import React from 'react';
import { useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { toNumber } from 'utils/toNumber';

import {
    useBooleanParam,
    useStringParam,
    useShowHelp,
} from 'hooks/routerHooks';
import { allScriptNames } from 'features/numerals/utils';
import { scripts } from 'features/numerals/scripts';
import { Filler, GridToolbar } from 'components/Toolbar';

import { Help } from './SankhyaHelp';
import { NumeralInfo } from 'features/numerals/NumeralInfo';
import { useSetTitle } from 'hooks/appDataHooks';
import { VerticalDivider } from 'components/Toolbar';
import { ScriptBaseMultiples } from 'features/numerals/ScriptBaseMultiples';
import { TextInputForNumberParam } from 'components/TextInputForNumberParam';
import { TextInputForRowsParam } from './Sankhya';

import { ButtonPageSettings } from 'components/ButtonPageSettings';
import { DropdownForScriptOnPage } from 'features/numerals/DropdownForScriptOnPage';

export function SankhyaBaseMultiples() {
    useSetTitle('Numeral Systems : Base Multiples');
    const showHelp = useShowHelp();
    return showHelp ? <Help /> : <Main />;
}

const Main = () => (
    <>
        <GridToolbar>
            <DropdownForScriptOnPage />
            <Filler />
            <WesternNumeralsSettingsIcon />
            <VerticalDivider />
            <ShowInfoButton />
            <ButtonPageSettings />
        </GridToolbar>
        <SettingsPanel />
        <PanelScriptInfo />

        <Table />
    </>
);

function SettingsPanel() {
    const [showSettings] = useBooleanParam('show-settings', false);
    if (!showSettings) return null;
    return (
        <GridToolbar>
            <NumberBaseParameterInput />
            <TextInputForRowsParam />
            <ColumnParameterInput />
        </GridToolbar>
    );
}

function WesternNumeralsSettingsIcon() {
    const [value, onClick] = useBooleanParam('show-western', false);
    const sx = { color: 'black', cursor: 'pointer' };
    const props = { sx, onClick };
    if (value) return <VisibilityOffIcon {...props} />;
    return <VisibilityIcon {...props} />;
}

function ShowInfoButton() {
    const [showNotes, toggleShowNotes] = useBooleanParam('show-info', false);
    const [script] = useStringParam('script');

    const hasInfo = scripts[script ?? 'Marathi']?.groups != null;
    if (!hasInfo) return null;

    return (
        <InfoIcon
            sx={{
                color: showNotes ? 'green' : 'black',
                cursor: 'pointer',
            }}
            onClick={toggleShowNotes}
        />
    );
}

function PanelScriptInfo() {
    const [showInfo] = useBooleanParam('show-info', false);
    const [script] = useStringParam('script');
    if (!showInfo) return null;

    return <NumeralInfo script={script ?? 'Marathi'} />;
}

function Table() {
    const [params] = useSearchParams();
    const script = params.get('script') ?? 'Marathi';
    let rows = toNumber(params.get('rows'));
    let columns = toNumber(params.get('columns'));
    const showWestern = (params.get('show-western') ?? '0') === '1';
    let base = toNumber(params.get('base'));

    if (!allScriptNames.includes(script ?? ''))
        return <h2>{`Error ! Script not found : "${script}"`}</h2>;

    return (
        <ScriptBaseMultiples
            script={script}
            multiples={rows}
            columns={columns}
            base={base}
            style={{ width: '100%' }}
            showWestern={showWestern}
        />
    );
}

export function ColumnParameterInput() {
    return (
        <TextInputForNumberParam
            name='columns'
            label='Columns'
        />
    );
}

export function NumberBaseParameterInput() {
    return (
        <TextInputForNumberParam
            name='base'
            label='Base'
        />
    );
}
