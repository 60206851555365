import React from 'react';

import { Link } from 'react-router-dom';

import { InfoPanel, InfoText } from 'components/InfoText';
import { AboutSankhya } from 'pages/SankhyaHelp';
import { AboutSankhyaSbs } from 'pages/SankhyaSbsHelp';
import { AboutSankhyaClock } from 'pages/SankhyaClockHelp';
import { useSetTitle } from 'hooks/appDataHooks';
import { Accordion } from 'components/Accordian';
import { Numeral } from 'features/numerals/Numeral';

interface Page {
    symbol: React.ReactNode;
    title: string;
    summary: React.ReactNode;
    location: string;
}

const pages: Page[] = [
    {
        symbol: <span className='Agean'>𐄢</span>,
        title: 'Numeral Systems',
        summary: <AboutSankhya />,
        location: '/sankhya',
    },
    {
        symbol: <span className='Agean'>𐄣</span>,
        title: 'Numeral Systems Side By Side',
        summary: <AboutSankhyaSbs />,
        location: '/sankhya-sbs',
    },
    {
        symbol: <span className='Symbol2'>⏱</span>,
        title: 'Numeral System Clocks',
        summary: <AboutSankhyaClock />,
        location: '/sankhya-clock',
    },
];

export function HomePage() {
    useSetTitle('Shankasur');

    //const rand = 18;
    //Math.floor(Math.random() * numeralSystemNames.length);
    const script: string = 'Aegean';
    return (
        <>
            <InfoPanel reverse={true}>
                <InfoText reverse={true}>
                    <div
                        style={{
                            padding: '10px 0px 10px 5px',
                        }}>
                        Welcome to Shankasur. Shankasur is a collection of apps
                        I wrote for fun. I hope you like those.
                    </div>
                </InfoText>

                <div
                    style={{
                        padding: '10px 0px 20px 10px',
                    }}>
                    {pages.map((page, index: number) => {
                        page.symbol = (
                            <Numeral
                                script={
                                    script === 'Cistercian' ? 'Marathi' : script
                                }
                                num={index + 1}
                                style={{ fontSize: 12 }}
                            />
                        );
                        return (
                            <PageInfo
                                page={page}
                                key={index}
                            />
                        );
                    })}
                </div>
            </InfoPanel>
        </>
    );
}

function PageInfo({ page }: { page: Page }) {
    const Lnk = ({ children }: { children: React.ReactNode }) => (
        <Link
            to={page.location}
            style={{ textDecoration: 'none' }}>
            {children}
        </Link>
    );

    const summary = (
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
            <Lnk>{page.title}</Lnk>&nbsp;&nbsp;
        </span>
    );

    const details = (
        <div style={{ fontSize: 16 }}>
            {page.summary}
            {` Click `}
            <Lnk>here</Lnk>
            {` to see the app`}.
        </div>
    );

    const props = { summary, details };

    return <Accordion {...props} />;
}
