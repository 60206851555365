import React from 'react';
import { IconButton } from '@mui/material';

import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import { generateSequence } from 'utils/maths';
import { TableCellForNumeral } from 'features/numerals/TableCellForNumeral';
import {
    allScriptNames,
    getScriptNamesForGroup,
} from 'features/numerals/utils';
import { Filler, VerticalDivider, GridToolbar } from 'components/Toolbar';

import { Help } from './SankhyaSbsHelp';
import { usePageParam, useShowHelp, useStringParam } from 'hooks/routerHooks';
import { useSetTitle } from 'hooks/appDataHooks';
import { TextInputForSequenceParam } from './Sankhya';

import { DropdownForScript } from 'features/numerals/DropdownForScript';
import { ButtonsPageNavigation } from 'components/ButtonsPageNavigation';
import { Table } from 'components/Table';
import { useSwipeable } from 'react-swipeable';
import {
    DropdownForGroupsOnPage,
    useGroup,
} from 'features/numerals/DropdownForGroupsOnPage';
import { Numeral } from 'features/numerals/Numeral';

export function SankhyaSbs() {
    useSetTitle('Numeral Systems Side By Side');
    const showHelp = useShowHelp();
    if (showHelp) return <Help />;
    return (
        <>
            <GridToolbar>
                <DropdownForGroupsOnPage style={{ width: '140px' }} />
            </GridToolbar>
            <GridToolbar>
                <TextInputForSequenceParam sx={{ width: '140px' }} />
                <Filler />

                <PanelCurrentNumbers />

                <ButtonsPageNavigation />
            </GridToolbar>
            <TableOfAllScripts />
            <ToolbarForScripts />
        </>
    );
}

function useScripts(): [string[], (scripts: string[]) => void] {
    const [group] = useGroup();
    const [_scripts, _setScripts] = useStringParam('scripts');

    function getScriptsForGroup() {
        if (group == null || group === 'Custom')
            return (_scripts ?? 'Marathi')
                .split(',')
                .filter(s => allScriptNames.includes(s));

        return getScriptNamesForGroup(group).split(',');
    }

    const scripts = getScriptsForGroup();

    function setScripts(newScripts: string[]) {
        _setScripts(newScripts.join(','));
    }
    return [scripts, setScripts];
}

function ButtonAddScript() {
    const [scripts, setScripts] = useScripts();

    const addScript = () => {
        const newScript = allScriptNames.find(
            script => !scripts.includes(script)
        );
        if (newScript == null) return;

        scripts.push(newScript);
        setScripts(scripts);
    };

    const style = { cursor: 'pointer', color: 'blue' };
    return (
        <IconButton onClick={addScript}>
            <Add style={style} />
        </IconButton>
    );
}

function ButtonRemoveLastScript() {
    const [scripts, setScripts] = useScripts();

    const removeLastScript = () => {
        if (scripts.length <= 1) return;
        scripts.pop();
        setScripts(scripts);
    };
    const disabled = scripts.length <= 1;
    const style = { cursor: 'pointer', color: disabled ? 'gray' : 'blue' };
    return (
        <IconButton
            onClick={removeLastScript}
            disabled={disabled}>
            <Remove style={style} />
        </IconButton>
    );
}

function ButtonAddAllScripts() {
    const [scripts, setScripts] = useScripts();

    const removeLastScript = () => {
        setScripts(
            scripts.concat(allScriptNames.filter(s => !scripts.includes(s)))
        );
    };
    const disabled =
        allScriptNames.filter(s => !scripts.includes(s)).length <= 0;
    const style = { cursor: 'pointer', color: disabled ? 'gray' : 'blue' };
    return (
        <IconButton
            onClick={removeLastScript}
            disabled={disabled}>
            <AddCircleOutline style={style} />
        </IconButton>
    );
}

function ButtonRemoveAllScripts() {
    const [scripts, setScripts] = useScripts();

    const removeLastScript = () => {
        scripts.splice(1);
        setScripts(scripts);
    };
    const disabled = scripts.length <= 1;
    const style = { cursor: 'pointer', color: disabled ? 'gray' : 'blue' };
    return (
        <IconButton
            onClick={removeLastScript}
            disabled={disabled}>
            <RemoveCircleOutline style={style} />
        </IconButton>
    );
}

function ToolbarForScripts() {
    const [group] = useGroup();
    if (group !== 'Custom') return null;
    return (
        <GridToolbar>
            <ButtonAddScript />
            <ButtonRemoveLastScript />
            <ButtonAddAllScripts />
            <ButtonRemoveAllScripts />
            <VerticalDivider />
        </GridToolbar>
    );
}

const numeralCellStyle: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    padding: '2px 2px 2px 2px',
    verticalAlign: 'middle',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '80px',
    textAlign: 'center',
};
function TableCellForNumeralSelector({ row }: { row: number }) {
    const [scripts, setScripts] = useScripts();
    const script = scripts[row];

    function modifyScript(newScript: string) {
        if (row > scripts.length) return;
        scripts[row] = newScript;

        setScripts(scripts);
    }

    return (
        <td
            style={{
                ...numeralCellStyle,
                position: 'sticky',
                left: 0,
                zIndex: 1,
                background: 'white',
            }}>
            <DropdownForScript
                script={script}
                setScript={newScript => modifyScript(newScript)}
                style={{
                    backgroundColor: 'white',
                    fontSize: 12,
                    fontWeight: 'normal',
                    width: '100px',
                    border: 'none',
                }}
            />
        </td>
    );
}

function useNumbers() {
    const { page } = usePageParam();
    const [sequence] = useStringParam('sequence');
    return generateSequence(sequence ?? '', page ?? 1);
}

function TableOfAllScripts() {
    const [scripts] = useScripts();
    const numbers = useNumbers();
    const { goToPreviousPage, goToNextPage } = usePageParam();

    function getCell(r: number, c: number, key: string) {
        if (c === 0)
            return (
                <TableCellForNumeralSelector
                    row={r}
                    key={key}
                />
            );
        const script = scripts[r];
        const num = numbers[c - 1];
        return (
            <TableCellForNumeral
                script={script}
                num={num}
                key={key}
                style={{
                    backgroundColor:
                        script === 'Cistercian' ? 'green' : undefined,
                    resize: script === 'Cistercian' ? 'none' : undefined,
                }}>
                <Numeral
                    num={num}
                    script={script}
                    style={{ height: 'Cistercian' ? '40px' : undefined }}
                />
            </TableCellForNumeral>
        );
    }

    const style: React.CSSProperties = {
        border: '2px rgb(220, 220, 220) solid',
        borderCollapse: 'collapse',

        marginBottom: '5px',
        width: '100%',
        marginTop: '10px',
    };

    const props = {
        rows: scripts.length,
        columns: 11,
        getCell,
        style: { ...style },
    };

    const handlers = useSwipeable({
        onSwipedRight: () => goToPreviousPage(),
        onSwipedLeft: () => goToNextPage(),
        swipeDuration: 250,
    });

    return (
        <div {...handlers}>
            <Table {...props} />
        </div>
    );
}

const numbersInfoStyle: React.CSSProperties = {
    paddingRight: '10px',
    marginBottom: 0,
};
function PanelCurrentNumbers() {
    const numbers = useNumbers();
    const _numbers = `${numbers[0]} - ${numbers[numbers.length - 1]}`;
    return <div style={numbersInfoStyle}>{_numbers}</div>;
}
