import { useStringParam } from 'hooks/routerHooks';

import { CommonProps } from 'components/common';

import { DropdownForScript } from 'features/numerals/DropdownForScript';
import { isScriptValid } from 'features/numerals/utils';

export function useScript(
    defaultScript: string | undefined = 'Marathi'
): [string | undefined, (_: string) => void] {
    const [script, setScript] = useStringParam('script');
    const _script = isScriptValid(script) ? script : defaultScript;
    return [_script, setScript];
}

export function DropdownForScriptOnPage(props: CommonProps) {
    const [script, setScript] = useScript();
    const _props = { script, setScript, ...props };
    return <DropdownForScript {..._props} />;
}
