import { Link } from 'react-router-dom';

export function Credits() {
    return (
        <>
            <h4>Thanks to individuals for verifying specific scripts</h4>
            <ol>
                <li>
                    Kharoshti{' - '}
                    <a href='mailto: brahmilipi@gmail.com'>Dr Anirban Dash</a>
                </li>
                <li>
                    Malayalam{' - '}
                    <a href='mailto: narendranathanpv@gmail.com'>
                        Narendranathan P V
                    </a>
                </li>
            </ol>

            <h4>Credits and References for all Scripts</h4>
            <ol>
                <li>
                    Google for doing an excellent work and providing the fonts
                    via{' '}
                    <Link to='https://fonts.google.com/noto'>
                        Noto family of fonts
                    </Link>
                    . This page would not have been possible without it.
                </li>
                <li>
                    <Link to='https://www.wikipedia.org'>wikipedia</Link>
                </li>
                <li>
                    <Link to='www.omniglot.com'>Omniglot</Link>
                </li>
                <li>
                    <Link to='https://scriptsource.org'>ScriptSource</Link>
                </li>
                <li>
                    <Link to='https://r12a.github.io'>r12a</Link>
                </li>
                <li>
                    <Link to='www.endangeredalphabets.net'>
                        Endangered Alphabets
                    </Link>
                </li>
                <li>
                    <Link to='https://www.storyofmathematics.com'>
                        The Story of Mathematics
                    </Link>
                </li>
            </ol>
        </>
    );
}
