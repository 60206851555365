import React from 'react';
import { scripts } from './scripts';
import { Cistercian } from './Cistercian';
import { CommonProps } from 'components/common';

export function Numeral({ script, num, style = {} }: Props) {
    if (num == null || !/^\d+$/.test(`${num}`)) return <UnknownNumeral />;

    const _props = { num, script, style };

    if (script === 'Cistercian') return <Cistercian {..._props} />;
    return <OtherNumeral {..._props} />;
}

function OtherNumeral({
    num,
    script,
    style = {},
}: {
    num: number | undefined;
    script: string;
    style: React.CSSProperties;
}) {
    const { converter, cssClassName = script } = scripts[script] ?? {};

    if (converter == null || num == null) return <span>?</span>;

    const numeral = converter(num);

    const spaceSuffix = Array(numeral.split(' ').length - 1)
        .fill(1)
        .map((_, i) => <span key={i}>&nbsp;&nbsp;</span>);

    return (
        <span className={cssClassName}>
            {numeral}
            {spaceSuffix}
        </span>
    );
}

interface Props extends CommonProps {
    num?: number;
    script: string;
}

const UnknownNumeral = () => <span>?</span>;
