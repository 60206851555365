import { Link } from 'react-router-dom';
import { Credits } from 'features/numerals/Credits';

export function AboutSankhyaSbs() {
    return (
        <>
            The <Link to='/sankhya'>Numeral Systems</Link> app displays numbers
            for a single script. Use this app to view Numerals in multiple
            Numeral systems symultaneously.
        </>
    );
}

export function Help() {
    return (
        <div
            style={{
                paddingTop: '20px',
                paddingLeft: '20px',
                paddingRight: '5px',
            }}>
            <AboutSankhyaSbs />
            <br />
            <br />
            Add the scripts you like to see together. Specify a different
            sequence of numbers via settings. You can also specify an arbitrary
            set of numbers in sequence.
            <br />
            <br />
            Notice the url changes when you customize the settings. You can
            bookmark the link for later reference.
            <br />
            <br />
            One caution before you explore. Most of the numerals are not peer
            reviwed. I am not an expert in the field either. I wanted to see
            numbers without any distraction or advertisements and did not find
            any. So I thought, I will write one. I have given reference for each
            Numeral. Drop me an email if you find issues.
            <br />
            <br />
            <h4>Suggested Settings</h4>
            <ol>
                <li>
                    <Link to='/sankhya-sbs?scripts=Western,Mayan&sequence=0,20'>
                        Mayan with Custom Sequence, Rows and Columns
                    </Link>
                </li>
                <li>
                    <Link to='/sankhya-sbs?scripts=Brahmi,Egyptian,Kharoshti,Sharada,Mayan,Mesopotamian&sequence=10,20,30,40,50'>
                        Some Ancient Scripts
                    </Link>
                </li>

                <li>
                    <Link to='/sankhya-sbs?scripts=Arabic,Persian,Urdu'>
                        Arabic, Persian and Urdu
                    </Link>
                </li>
                <li>
                    <Link to='/sankhya-sbs?scripts=Brahmi,Devanagari,Nandinagari'>
                        Brahmi, Devanagari and Nandinagari
                    </Link>
                </li>
                <li>
                    <Link to='/sankhya-sbs?scripts=Malayalam,Tamil,Kannada,Telugu,Modi,Marathi'>
                        South Indian
                    </Link>
                </li>
            </ol>
            <Credits />
        </div>
    );
}
