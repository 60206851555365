import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CommonProps } from 'components/common';

interface Props extends CommonProps {
    summary: React.ReactNode;
    details: React.ReactNode;
}
export function Accordion({ summary, details }: Props) {
    return (
        <MuiAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {summary}
            </AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
        </MuiAccordion>
    );
}
