import React, { useMemo } from 'react';
import { Select } from 'components/Select';
import { getAllGroups } from 'features/numerals/utils';
import { BORDER, Style } from 'components/common';

export function DropdownForGroups({ group, setGroup, style = {} }: Props) {
    const props = {
        value: group ?? 'Custom',
        onChange: setGroup,
        style: Style(_style, style),
    };

    const options = useMemo(createScriptOptions, []);
    return <Select {...props}>{options}</Select>;
}

const _style = Style({
    height: '40px',
    border: BORDER,
});

export interface Props {
    setGroup?: (script: string) => void;
    group?: string | null;
    style?: React.CSSProperties;
}

function option(group: string, key: number) {
    return (
        <option
            value={group}
            key={key}>
            {group}
        </option>
    );
}

function createScriptOptions() {
    return (
        <React.Fragment>
            {['Custom', ...getAllGroups()]
                .sort((a, b) => a.localeCompare(b))
                .map(option)}
        </React.Fragment>
    );
}
