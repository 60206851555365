export function AppFooter() {
    const email = 'shankasur@hotmail.com';

    return (
        <footer>
            <hr style={{ border: '0.1px solid lightgray' }} />
            {`Shankasur version 0.2.15. Feedback `}
            <a href={'mailto:' + email}>{email}</a>
            <br />
            <a
                rel='license'
                href='http://creativecommons.org/licenses/by-nc/4.0/'>
                This work is licensed under CC BY-NC 4.0
            </a>
        </footer>
    );
}
