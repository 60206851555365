export function InfoPanel({
    children,
    reverse,
}: {
    children: React.ReactNode;
    reverse?: boolean;
}) {
    return (
        <div
            style={{
                margin: '0px 0px 0px 0px',
                padding: '5px px 0px 0px',
                fontSize: 16,
                backgroundColor: reverse ? 'black' : 'whitesmoke',
            }}>
            {children}
        </div>
    );
}

export function InfoText({
    reverse = false,
    children,
}: {
    reverse?: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            style={{
                color: reverse ? 'whitesmoke' : 'black',
                padding: '0px 0px 0px 5px',
            }}>
            {children}
        </div>
    );
}
