import * as React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import RestoreIcon from '@mui/icons-material/Restore';

import { usePageLocation } from 'hooks/routerHooks';

import { Filler, Toolbar } from 'components/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useGetTitle } from 'hooks/appDataHooks';

export function AppHeader() {
    return (
        <Toolbar style={style}>
            <ButtonGotoHome />
            <Title />
            <Filler />
            <ButtonSettingsRestore />
            <ButtonShowHelp />
        </Toolbar>
    );
}

const style = {
    backgroundColor: 'black',
    color: 'whitesmoke',
    padding: 0,
    width: '100%',
    minHeight: '50px',
};

function ButtonGotoHome() {
    const navigate = useNavigate();
    return (
        <IconButton
            onClick={() => navigate('/')}
            style={{ padding: '0px 0px 0px 5px' }}>
            <HomeIcon
                fontSize='medium'
                sx={{ color: 'white' }}
            />
        </IconButton>
    );
}

function Title() {
    const title = useGetTitle();
    return (
        <div
            style={{
                fontWeight: 'bold',
                padding: '5px 0px 0px 0px',
            }}>
            {title}
        </div>
    );
}

function ButtonShowAboutPage({ onClick }: { onClick: () => void }) {
    return (
        <IconButton onClick={onClick}>
            <span
                className='Egyptian'
                style={{ color: 'white', fontSize: 24 }}>
                𓀎
            </span>
        </IconButton>
    );
}

function ButtonShowHelp() {
    const [location, setLocation] = usePageLocation();
    if (location.toLowerCase() === '/about') return null;

    const hasHelp = location === '/' || location.endsWith('help');

    const helpLocation = hasHelp ? '/about' : `${location}/help`;
    const goToHelpPage = () => setLocation(helpLocation);

    if (helpLocation === '/about')
        return <ButtonShowAboutPage onClick={goToHelpPage} />;

    return (
        <IconButton onClick={goToHelpPage}>
            <HelpIcon
                fontSize='medium'
                sx={{ color: 'white' }}
            />
        </IconButton>
    );
}

function ButtonSettingsRestore() {
    const [params, setParams] = useSearchParams();
    if (params.size <= 0) return null;

    return (
        <IconButton
            onClick={() => setParams({})}
            style={{ padding: '0px 0px 0px 0px' }}>
            <RestoreIcon
                fontSize='medium'
                sx={{ color: 'white' }}
            />
        </IconButton>
    );
}
