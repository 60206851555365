import React from 'react';
import { Numeral } from './Numeral';
import { CommonProps } from 'components/common';
import { Table } from 'components/Table';
import { scripts } from './scripts';
import { WesternNumeralInCell } from './TableCellForNumeral';

const cellStyle: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    padding: '5px 5px 5px 5px',
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'relative',
    whiteSpace: 'nowrap',
};

interface Props extends CommonProps {
    script: string;
    multiples?: number;
    columns?: number;
    base?: number;
    showWestern?: boolean;
}

export function ScriptBaseMultiples(props: Props) {
    const { script, multiples = 6, base, columns, style = {} } = props;
    const _base = base ?? scripts[script]?.base ?? 10;

    const _props = {
        rows: Math.max(multiples, 2) + 2,
        columns: columns == null ? _base : Math.max(columns, 2),
        getCell: (r: number, c: number) => getCell(r, c, props),
        style,
    };
    return <Table {..._props}></Table>;
}

function getCell(r: number, c: number, props: Props) {
    const { script, base, showWestern = false } = props;
    const _base = base ?? scripts[script]?.base ?? 10;

    const key = `${r}-${c}`;
    const _f = Math.max(0, r - 1);
    const f = Math.pow(_base, Math.max(0, r - 1));

    if (c === 0) {
        if (r === 0)
            return (
                <FirstCell
                    id={key}
                    key={key}
                />
            );
        return (
            <FirstRowCell
                id={key}
                key={key}>
                {`${_base}`}
                <sup>{_f}</sup>
            </FirstRowCell>
        );
    }

    if (r === 0)
        return (
            <FirstColumnCell
                id={key}
                key={key}>
                {c}
            </FirstColumnCell>
        );
    const num = c * f;
    return (
        <td
            key={key}
            style={{
                ...cellStyle,
                textAlign: script === 'Mayan' ? 'center' : 'right',
            }}>
            <Numeral
                script={script}
                num={num}
            />
            {!showWestern ? null : <WesternNumeralInCell num={num} />}
        </td>
    );
}

const FirstCell = ({ id }: { id: string }) => (
    <th
        key={id}
        style={cellStyle}>
        .
    </th>
);

const FirstRowCell = ({
    id,
    children,
}: {
    id: string;
    children: React.ReactNode;
}) => (
    <th
        key={id}
        style={{ ...cellStyle, textAlign: 'right' }}>
        {children}
    </th>
);

const FirstColumnCell = ({
    id,
    children,
}: {
    id: string;
    children: React.ReactNode;
}) => (
    <th
        key={id}
        style={cellStyle}>
        {children}
    </th>
);
