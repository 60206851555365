import React, { useMemo } from 'react';
import { Select } from 'components/Select';
import { allScriptNames } from 'features/numerals/utils';
import { BORDER, Style } from 'components/common';

export function DropdownForScript({ script, setScript, style = {} }: Props) {
    const props = {
        value: script ?? 'Marathi',
        onChange: setScript,
        style: Style(_style, style),
    };

    const options = useMemo(createScriptOptions, []);
    return <Select {...props}>{options}</Select>;
}

const _style = Style({
    height: '40px',
    border: BORDER,
});

export interface Props {
    setScript?: (script: string) => void;
    script?: string | null;
    style?: React.CSSProperties;
}

function option(script: string, key: number) {
    return (
        <option
            value={script}
            key={key}>
            {script}
        </option>
    );
}

function createScriptOptions() {
    return <React.Fragment>{allScriptNames.map(option)}</React.Fragment>;
}
