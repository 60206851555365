import React from 'react';

import { Table } from 'components/Table';
import { CommonProps } from 'components/common';
import {
    TableCellForNumeral,
    WesternNumeralInCell,
} from './TableCellForNumeral';
import { Numeral } from './Numeral';

const _style: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    borderCollapse: 'collapse',
    width: '100%',
};

interface Props extends CommonProps {
    script: string;
    numbers: number[][];
    showWestern: boolean;
}

export function TableOfNumerals({ script, numbers, showWestern }: Props) {
    function getCell(r: number, c: number) {
        const num = numbers[r][c];
        return (
            <TableCellForNumeral
                key={num}
                num={num}
                script={script}
                showWestern={showWestern}>
                <Numeral
                    num={num}
                    script={script}
                />
                {showWestern ? <WesternNumeralInCell num={num} /> : null}
            </TableCellForNumeral>
        );
    }

    const props = {
        rows: numbers.length,
        columns: numbers[0].length,
        getCell,
        style: { ..._style },
    };

    return <Table {...props} />;
}
