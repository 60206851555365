import React from 'react';

import { IconButton } from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { usePageParam } from 'hooks/routerHooks';

export function ButtonsPageNavigation() {
    const { page, goToFirstPage, goToPreviousPage, goToNextPage } =
        usePageParam();

    return (
        <React.Fragment>
            <IconButton onClick={goToFirstPage}>
                <FirstPageIcon
                    sx={{
                        cursor: page > 1 ? 'pointer' : 'default',
                        color: page > 1 ? 'black' : 'grey',
                    }}
                />
            </IconButton>
            <IconButton onClick={goToPreviousPage}>
                <NavigateBeforeIcon
                    sx={{
                        cursor: page > 1 ? 'pointer' : 'default',
                        color: page > 1 ? 'black' : 'grey',
                    }}
                />
            </IconButton>
            <IconButton onClick={goToNextPage}>
                <NavigateNextIcon
                    sx={{
                        cursor: 'pointer',
                        color: 'black',
                    }}
                />
            </IconButton>
        </React.Fragment>
    );
}
