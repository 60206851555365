import { scripts } from './scripts';

export const isScriptValid = (script: string | null | undefined) =>
    allScriptNames.includes(script ?? '');

export const allScriptNames = Object.keys(scripts)
    .map(script => script)
    .sort((a, b) => a.localeCompare(b));

export const getScriptNamesForGroup = (group: string) =>
    allScriptNames.filter(n => scripts[n].groups?.includes(group)).join(',');

export const getScriptGroups = (script: string) =>
    scripts[script]?.groups?.map(group => ({
        name: group,
        scripts: getScriptNamesForGroup(group),
    })) ?? [];

export const getAllGroups = () => {
    const groups = new Set<string>([]);

    Object.keys(scripts)
        .map(k => scripts[k])
        .filter(s => s.groups != null)
        .forEach(g => g.groups?.forEach(n => groups.add(n)));

    return Array.from(groups);
};

export const allGroupNames = getAllGroups();

export const isGroupValid = (group: string) => allGroupNames.includes(group);

export const getQuiquesymbols = (script: string) =>
    scripts[script]?.uniqueSymbols;
