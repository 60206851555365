import React from 'react';
import { Credits } from 'features/numerals/Credits';
import { allScriptNames } from 'features/numerals/utils';

export function AboutSankhya() {
    return (
        <span>
            Over centuries humans used symbols to express numbers and then
            combined them to represent bigger numbers. This small app displays
            Numerals in {allScriptNames.length} different scripts/languages.
        </span>
    );
}

export function Help() {
    return (
        <div
            style={{
                paddingTop: '20px',
                paddingLeft: '20px',
                paddingRight: '5px',
            }}>
            <AboutSankhya />
            It is fascinating to see how ancient humans used numbers, how they
            changed over time. Teachers and students may enjoy this
            no-distraction app.
            <br />
            <br />
            Select your script and scroll through the pages. View Western
            Numeral alongwith the displayed one. Click settings button to
            configure a different sequence, or a different number of rows or
            columns. You can always reset the settings to the default ones. You
            can view related scripts using 'info' button. Notice the url changes
            when you customize the settings. You can bookmark the Link for later
            reference.
            <br />
            <br />
            I was not able to represent all numerals accurately. For example
            Mesopotamian can be written right-to-left, or top-to-bottom. I
            provided only the left-to-right style. Moreover, I have not captured
            other variations of the scripts. For example contemporary Malyalam
            uses 'zero'. I have not provided this zero-based representation yet.
            However, I do intend to add it future. I also have not covered a
            majority of East and West Asian scripts, which I intend to add in
            future.
            <br />
            <br />
            One caution before you explore. Most of the numerals are not peer
            reviewed. I am not an expert in the field either. Please drop me an
            email if you find an error.
            <Credits />
        </div>
    );
}
