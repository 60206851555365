import { toNumber } from './toNumber';
import { transposeArray } from './transposeArray';

export const limit = (x: number, min: number, max: number) =>
    Math.min(Math.max(x, min), max);

export function generate2DSequence(
    props: string,
    page: number,
    rows: number,
    columns: number
): number[][] {
    if (rows <= 0) rows = 10;
    if (columns <= 0) columns = 10;

    function extractOp(str: string) {
        const factor = Number(str === '' ? '1' : str);
        const f = isNaN(factor) ? 1 : factor;
        return (a: number, n: number, factor: number = f) => {
            return a + factor * (n - 1);
        };
    }

    const _props = props ?? '';

    const [seed, rowFormula, columnFormula]: (string | undefined)[] = _props
        .trim()
        .replaceAll(' ', '')
        .split(',');

    let firstNumber = seed === '' ? 1 : Number(seed);
    if (typeof firstNumber != 'number' || isNaN(firstNumber)) firstNumber = 1;

    const rOp = extractOp(rowFormula);
    const cOp = columnFormula == null ? undefined : extractOp(columnFormula);

    const _page = page > 1 ? page : 1;
    firstNumber = cOp
        ? cOp(firstNumber, columns * (_page - 1) + 1)
        : rOp(firstNumber, (_page - 1) * (rows * columns) + 1);

    const firstRow = [firstNumber]; //getsequence(firstNumber, nextInColumn);

    for (let i = 1; i < columns; i++) {
        const n = cOp
            ? cOp(firstNumber, i + 1)
            : rOp(firstNumber, rows * i + 1);

        firstRow.push(n);
    }

    const numbers: number[][] = [];
    firstRow.forEach((n: number) => {
        const seq: number[] = [n];
        for (let i = 1; i < rows; i++) {
            seq.push(rOp(n, i + 1));
        }
        numbers.push(seq);
    });

    return transposeArray(numbers);
}

export function generateSequence(
    props: string,
    page: number,
    numbersPerPage: number = 10
): (number | undefined)[] {
    const terms = (props ?? '').trim().replaceAll(' ', '').split(',');
    if (terms.length > 2) return terms.map(toNumber);

    const aseq = arithmaticSequence(toNumber(terms[0]), toNumber(terms[1]));
    const numbers = [];

    for (let n = 1; n <= numbersPerPage; n++) {
        const term = aseq(numbersPerPage * (page - 1) + n);
        numbers.push(term);
    }

    return numbers;
}

const arithmaticSequence =
    (a: number | undefined = 1, d: number | undefined = 1) =>
    (n: number) =>
        a + (n - 1) * d;
