import React from 'react';
import { toNumber } from 'utils/toNumber';

//#region Utility Functions
const westernDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

function toDec(
    digitChars: [
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string,
        string
    ]
): (num: number) => string {
    return (num: number) => {
        const digitsArray = `${num}`.split('').map(Number);
        if (digitsArray.some(d => !westernDigits.includes(`${d}`))) return '?';

        return digitsArray.map(digit => digitChars[digit] ?? '?').join('');
    };
}

function toSouthIndian({
    basic,
    extra,
    tens,
}: {
    basic: string[];
    extra: string[];
    tens: string[];
}): (num: number) => string {
    return (num: number) => {
        if (num === 0) return basic[0];
        const digitsArray = `${num}`.split('').map(Number);

        return digitsArray
            .reverse()
            .map((digit, position) => {
                if (digit === 0) return '';

                if (position === 0) return basic[digit];

                let str = extra[digit];
                let pos = position % 3;

                return (
                    str +
                    (pos === 0 ? '' : pos === 1 ? tens[0] : tens[1]) +
                    tens[2].repeat(position / 3)
                );
            })
            .reverse()
            .join('');
    };
}

//#endregion Utility Functions

export interface IScript {
    converter: (num: number) => string;
    cssClassName?: string;
    groups?: string[];
    base?: number;
    uniqueSymbols?: number[];
    references?: React.ReactNode[];
}

const toAegean = (minoan: boolean) => {
    const digits = [
        ['', '𐄇', '𐄈', '𐄉', '𐄊', '𐄋', '𐄌', '𐄍', '𐄎', '𐄏'],
        ['', '𐄐', '𐄑', '𐄒', '𐄓', '𐄔', '𐄕', '𐄖', '𐄗', '𐄘'],
        ['', '𐄙', '𐄚', '𐄛', '𐄜', '𐄝', '𐄞', '𐄟', '𐄠', '𐄡'],
        ['', '𐄢', '𐄣', '𐄤', '𐄥', '𐄦', '𐄧', '𐄨', '𐄩', '𐄪'],
        ['', '𐄫', '𐄬', '𐄭', '𐄮', '𐄯', '𐄰', '𐄱', '𐄲', '𐄳'],
    ];

    return (num: number) => {
        if (num >= 10000 && minoan) return '?';
        if (num >= 100000) return '?';

        const digitsArray = `${num}`.split('').reverse().map(Number);
        return digitsArray
            .map((d, i) => digits[i][d])
            .reverse()
            .join('');
    };
};
const Aegean: IScript = {
    converter: toAegean(false),
    cssClassName: 'Aegean',
    groups: ['Base 10', 'European', 'Aegean', 'Ancient', 'No Zero', 'Greek'],
    uniqueSymbols: [1, 10, 100, 1000, 10000],
};

const decimalUniqueSymbols = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const toArabic = toDec(['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']);
const Arabic: IScript = {
    converter: toArabic,
    groups: ['Base 10', 'Asian', 'Eastern Arabic'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toBalinise = toDec(['᭐', '᭑', '᭒', '᭓', '᭔', '᭕', '᭖', '᭗', '᭘', '᭙']);
const Balinise: IScript = {
    converter: toBalinise,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toBengali = toDec(['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']);
const Bengali: IScript = {
    converter: toBengali,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toBrahmi = toDec(['𑁦', '𑁧', '𑁨', '𑁩', '𑁪', '𑁫', '𑁬', '𑁭', '𑁮', '𑁯']);
const Brahmi: IScript = {
    converter: toBrahmi,
    groups: ['Base 10', 'Asian', 'Indian', 'Ancient'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toBraille = toDec(['⠚', '⠁', '⠃', '⠉', '⠙', '⠑', '⠋', '⠛', '⠓', '⠊']);
const Braille: IScript = {
    converter: toBraille,
    groups: ['Base 10', 'European'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toBurmese = toDec(['၀', '၁', '၂', '၃', '၄', '၅', '၆', '၇', '၈', '၉']);
const Burmese: IScript = {
    converter: toBurmese,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toCham = toDec(['꩐', '꩑', '꩒', '꩓', '꩔', '꩕', '꩖', '꩗', '꩘', '꩙']);
const Cham: IScript = {
    converter: toCham,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const Cistercian: IScript = {
    converter: (num: number) => `${num}`,
    groups: ['Base 10', 'European', 'Limited', 'No Zero'],
    uniqueSymbols: [
        ...decimalUniqueSymbols.filter(d => d !== 0),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => 10 * d),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => 100 * d),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => 1000 * d),
    ],
};

const toDevanagari = toDec(['०', '१', '२', '३', '४', '५', '६', '७', '८', '९']);
const Devanagari: IScript = {
    converter: toDevanagari,
    groups: ['Base 10', 'Devanagari', 'Indian', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toDzongkha = toDec(['༠', '༡', '༢', '༣', '༤', '༥', '༦', '༧', '༨', '༩']);
const Dzongkha: IScript = {
    converter: toDzongkha,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toEgyption(): (num: number) => string {
    const characters: string[][] = [
        ['', '𓏺', '𓏻', '𓏼', '𓏽', '𓏾', '𓏿', '𓐀', '𓐁', '𓐂'],
        ['', '𓎆', '𓎇', '𓎈', '𓎉', '𓎊', '𓎋', '𓎌', '𓎍', '𓎎'],
        ['', '𓍢', '𓍣', '𓍤', '𓍥󠄀', '𓍫', '𓍧', '𓍨', '𓍩', '𓍪'],
        ['', '𓆼', '𓆽', '𓆾', '𓆿', '𓇀', '𓇁', '𓇂', '𓇃', '𓇄'],
        ['', '𓂭', '𓂮', '𓂯', '𓂰', '𓂱', '𓂲', '𓂳', '𓂴', '𓂵'],
        ['', '𓆐', '', '', '', '', '', '', '', ''],
        ['', '𓁨', '', '', '', '', '', '', '', ''],
    ];
    characters[5].forEach(
        (c, i) => (characters[5][i] = i <= 0 ? '' : '𓆐'.repeat(i))
    );
    characters[6].forEach(
        (c, i) => (characters[6][i] = i <= 0 ? '' : '𓁨'.repeat(i))
    );

    return (num: number) => {
        if (num >= 10000000) return '?';

        const representation = `${num}`
            .split('')
            .reverse()
            .map(digit => Number(digit))
            .map((digit, position) => characters[position][digit])
            .filter(c => c != null && c !== '');
        return representation.reverse().join(' ');
    };
}
const Egyptian: IScript = {
    converter: toEgyption(),
    groups: [
        'Base 10',
        'African',
        'No Zero',
        'Limited',
        'Unordered',
        'Ancient',
    ],
    uniqueSymbols: [1, 10, 100, 1000, 10000, 100000, 1000000],
};

const toGujarati = toDec(['૦', '૧', '૨', '૩', '૪', '૫', '૬', '૭', '૮', '૯']);
const Gujarati: IScript = {
    converter: toGujarati,
    groups: ['Base 10', 'Indian', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toGurmukhi = toDec(['੦', '੧', '੨', '੩', '੪', '੫', '੬', '੭', '੮', '੯']);
const Gurmukhi: IScript = {
    converter: toGurmukhi,
    groups: ['Base 10', 'Indian', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toHebrew = (num: number): string => {
    const digits = [
        ['', 'א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט'],
        ['', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ'],
        ['', 'ק', 'ר', 'ש', 'ת', 'תק', 'תר', 'תש', 'תת', 'תתק'],
    ];

    let numArray = `${num}`
        .split('')
        .map((n: string) => toNumber(n) ?? 0)
        .reverse();

    let _numarray: string[] = [];

    numArray.forEach((d, i) => {
        if (i > 0 && i % 3 === 0) {
            _numarray.push('‘');
        }
        _numarray.push(digits[i % 3][d]);
    });

    let strnum = _numarray.join('');
    strnum = strnum.replaceAll('הי', 'וט');
    strnum = strnum.replaceAll('וי', 'זט');

    if (strnum.length > 1 && !strnum.startsWith('‘')) {
        const position = 1;
        strnum = [strnum.slice(0, position), '"', strnum.slice(position)].join(
            ''
        );
    }

    return strnum;
};
const Hebrew: IScript = {
    converter: toHebrew,
    cssClassName: 'Hebrew',
    groups: ['Base 10', 'Asian', 'Alphabetic', 'Ancient'],
    uniqueSymbols: [
        ...decimalUniqueSymbols.filter(d => d !== 0),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => d * 10),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => d * 100),
    ],
};

const Hindi: IScript = {
    converter: toDevanagari,
    cssClassName: 'Devanagari',
    groups: ['Base 10', 'Indian', 'Asian', 'Devanagari'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toJapanese = (num: number): string => {
    const digits = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];

    const myriadPowers = [
        '', //0
        '万', //10000 = 10^4
        '億', //10 0000 0000 = 10^8
        '兆',
        '京',
        '垓',
    ];

    if (num === 0) return '零';

    const numArray = `${num}`
        .split('')
        .map((n: string) => toNumber(n) ?? 0)
        .reverse();

    const toUnit = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : numArray[i] === 1 && i > 0
            ? ''
            : digits[numArray[i]];

    const toTens = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : `十${numArray[i] === 1 ? '' : digits[numArray[i]]}`;

    const toHundreds = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : `百${numArray[i] === 1 ? '' : digits[numArray[i]]}`;

    const toThoushands = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : `千${numArray[i] === 1 ? '' : digits[numArray[i]]}`;

    const toMyriadPower = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : myriadPowers[i / 4];

    const ichi = (i: number) =>
        i >= numArray.length
            ? ''
            : numArray[i] === 0
            ? ''
            : i === 0
            ? ''
            : numArray[i] === 1
            ? '一'
            : '';

    let result = '';

    for (let g = 0; g < numArray.length; g += 4) {
        const u = toUnit(g);
        const t = toTens(g + 1);
        const h = toHundreds(g + 2);
        const th = toThoushands(g + 3);
        const m =
            u !== '' || t !== '' || h !== '' || th !== ''
                ? myriadPowers[g / 4]
                : toMyriadPower(g);
        const i = ichi(g);
        result += `${m}${u}${t}${h}${th}${i}`;
    }
    //result += ichi;
    return result.split('').reverse().join('');

    //123456           4000     500    50  7
    //百万二十万   三万 四千 五百 六十 七;
    //    百二十  三万 四千 五百 六十 七;
};
const Japanese: IScript = {
    converter: toJapanese,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        Math.pow(10, 2),
        Math.pow(10, 3),
        Math.pow(10, 4),
        Math.pow(10, 8),
        Math.pow(10, 12),
        Math.pow(10, 16),
        Math.pow(10, 20),
    ],
};

const toKannada = toDec(['೦', '೧', '೨', '೩', '೪', '೫', '೬', '೭', '೮', '೯']);
const Kannada: IScript = {
    converter: toKannada,
    groups: ['Base 10', 'Indian', 'Asian', 'South Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toKharoshti(num: number): string {
    const digits = ['', '𐩀', '𐩁', '𐩂', '𐩃', '𐩃𐩀', '𐩃𐩁', '𐩃𐩂', '𐩃𐩃', '𐩃𐩃𐩀'];

    let res = '';

    const ten = '𐩄', //᭒
        twenty = '𐩅',
        hundred = '𐩆',
        thousand = '𐩇';

    if (num >= 2000) return '?';

    if (Math.trunc(num / 1000) === 1) res = thousand;
    num %= 1000;

    const hundreds = Math.trunc(num / 100);
    if (hundreds >= 1) {
        res += (hundreds > 1 ? digits[hundreds] : '') + hundred;
    }
    num %= 100;

    const tens = Math.trunc(num / 10);
    if (tens >= 1) {
        const twenties = Math.trunc(tens / 2);
        res += twenty.repeat(twenties);

        const remainingTens = tens % 2;
        res += ten.repeat(remainingTens);
    }
    num %= 10;
    res += digits[num];

    return res;
}
const Kharoshti: IScript = {
    converter: toKharoshti,
    groups: ['Base 10', 'Indian', 'Asian', 'No Zero', 'Limited'],
    uniqueSymbols: [1, 2, 3, 4, 10, 20, 100, 1000],
};

const toKhmer = toDec(['០', '១', '២', '៣', '៤', '៥', '៦', '៧', '៨', '៩']);
const Khmer: IScript = {
    converter: toKhmer,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toKhudawadi = toDec(['𑋰', '𑋱', '𑋲', '𑋳', '𑋴', '𑋵', '𑋶', '𑋷', '𑋸', '𑋹']);
const Khudawadi: IScript = {
    converter: toKhudawadi,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toKhojki = toDec(['꠰', '꠱', '꠲', '꠳', '꠴', '꠵', '꠶', '꠷', '꠸', '꠹']);
const Khojki: IScript = {
    converter: toKhojki,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toLepcha = toDec(['᱀', '᱁', '᱂', '᱃', '᱄', '᱅', '᱆', '᱇‎', '᱈', '᱉']);
const Lepcha: IScript = {
    converter: toLepcha,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toLao = toDec(['໐', '໑', '໒', '໓', '໔', '໕', '໖', '໗', '໘', '໙']);
const Lao: IScript = {
    converter: toLao,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
}; //variation exists

const toLimbu = toDec(['᥆', '᥇', '᥈', '᥉', '᥊', '᥋', '᥌', '᥍', '᥎', '᥏']);
const Limbu: IScript = {
    converter: toLimbu,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toOldMalayalam = toSouthIndian({
    basic: ['', '൧', '൨', '൩', '൪', '൫', '൬', '൭', '൮', '൯'],
    extra: ['', '', '൨', '൩', '൪', '൫', '൬', '൭', '൮', '൯'],
    tens: ['൰', '൱', '൲'],
});
const MalayalamOld: IScript = {
    converter: toOldMalayalam,
    cssClassName: 'Malayalam',
    groups: [
        'Base 10',
        'Asian',
        'South Indian',
        'Indian',
        'No Zero',
        'Malayalam',
    ],
    uniqueSymbols: [
        ...decimalUniqueSymbols.filter(d => d !== 0),
        10,
        100,
        1000,
    ],
};

const toMalayalam = toDec(['൦', '൧', '൨', '൩', '൪', '൫', '൬', '൭', '൮', '൯']);
const Malayalam: IScript = {
    converter: toMalayalam,
    groups: ['Base 10', 'Asian', 'South Indian', 'Indian', 'Malayalam'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toMangolian = toDec(['᠐', '᠑', '᠒', '᠓', '᠔', '᠕', '᠖', '᠗', '᠘', '᠙']);
const Mangolian: IScript = {
    converter: toMangolian,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const Marathi: IScript = {
    converter: toDevanagari,
    cssClassName: 'Devanagari',

    groups: [
        'Base 10',
        'Asian',
        'Indian',
        'Devanagari',
        'South Indian',
        'Marathi',
    ],
    uniqueSymbols: decimalUniqueSymbols,
};

const toMGondi = toDec(['𑵐', '𑵑', '𑵒', '𑵒', '𑵔', '𑵕', '𑵖', '𑵗', '𑵘', '𑵙']);
const MasaramGondi: IScript = {
    converter: toMGondi,
    groups: ['Base 10', 'Asian', 'Indian', 'South Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toMayan(num: number): string {
    const digits = [
        '𝋠',
        '𝋡',
        '𝋢',
        '𝋣',
        '𝋤',
        '𝋥',
        '𝋦',
        '𝋧',
        '𝋨',
        '𝋩',
        '𝋪',
        '𝋫',
        '𝋬',
        '𝋭',
        '𝋮',
        '𝋯',
        '𝋰',
        '𝋱',
        '𝋲',
        '𝋳',
    ];

    return num
        .toString(20)
        .split('')
        .map((i, pos) => digits[parseInt(i, 20)])
        .join('\n');
}
const Mayan: IScript = {
    converter: toMayan,
    groups: ['Base 10', 'Base 20', 'American', 'Ancient'],
    base: 20,
    uniqueSymbols: [0, 1, 2, 3, 4, 5],
};

const toMeitei = toDec(['꯰', '꯱', '꯲', '꯳', '꯴', '꯵', '꯶', '꯷', '꯸', '꯹']);
const Meitei: IScript = {
    converter: toMeitei,
    groups: ['Base 10', 'Asian', 'Indian', 'Northeast Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toMesopotamian(num: number): string {
    const digits = ['', '𒐕', '𒐖', '𒐗', '𒐘', '𒐕𒐕𒐕𒐕𒐕', '𒐙', '𒐚', '𒐛', '𒐜', '𒐝'];

    const tens = ['', '𒌋', '《', '𒌍', '𒐏', '𒐐'];

    let res = '';

    if (num === 0) return '';

    let input = [num];
    let i = 0;
    let _num = [];
    while (input[i] > 0) {
        _num[i] = input[i] % 60;
        input.push(Math.trunc(input[i] / 60));
        i += 1;
    }

    _num = _num.reverse();

    for (let j = 0; j < _num.length; j++) {
        let ten = Math.trunc(_num[j] / 10);
        let digit = _num[j] % 10;

        res += (j === 0 ? '' : ' ') + tens[ten] + digits[digit];
    }

    return res;
}
const Mesopotamian: IScript = {
    converter: toMesopotamian,
    groups: ['Base 10', 'Base 60', 'Asian', 'Ancient'],
    base: 60,
    uniqueSymbols: [1, 10],
};

const Minoan: IScript = {
    converter: toAegean(true),
    cssClassName: 'Aegean',
    groups: ['Base 10', 'Eurpean', 'Aegean', 'Ancient', 'No Zero', 'Greek'],
    uniqueSymbols: [1, 10, 100, 1000],
};

const toModi = toDec(['𑙐', '𑙑', '𑙒', '𑙓', '𑙔', '𑙕', '𑙖', '𑙗', '𑙘', '𑙙']);
const Modi: IScript = {
    converter: toModi,
    groups: ['Base 10', 'Asian', 'Indian', 'South Indian', 'Marathi'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toMultani = toDec(['੦', '੧', '੨', '੩', '੪', '੫', '੬', '੭', '੮', '੯']);
const Multani: IScript = {
    converter: toMultani,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const Mycenaean = Aegean;

const toNandinagari = toDec(['೦', '೧', '೨', '೩', '೪', '೫', '೬', '೭', '೮', '೯']);
const Nandinagari: IScript = {
    converter: toNandinagari,
    groups: ['Base 10', 'Asian', 'Indian', 'South Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toOdia = toDec(['୦', '୧', '୨', '୩', '୪', '୫', '୬', '୭', '୮', '୯']);
const Odia: IScript = {
    converter: toOdia,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toPersian = toDec(['٠', '١', '٢', '٣', '۴', '۵', '۶', '٧', '٨', '٩']);
const Persian: IScript = {
    converter: toPersian,
    groups: ['Base 10', 'Asian', 'Eastern Arabic'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toRoman(num: number): string {
    const huns = ['', 'Ⅽ', 'ⅭⅭ', 'ⅭⅭⅭ', 'ⅭⅮ', 'Ⅾ', 'ⅮⅭ', 'ⅮⅭⅭ', 'ⅮⅭⅭⅭ', 'ⅭⅯ'];
    const tens = ['', 'Ⅹ', 'ⅩⅩ', 'ⅩⅩⅩ', 'ⅩⅬ', 'Ⅼ', 'LⅩ', 'ⅬⅩⅩ', 'ⅬⅩⅩⅩ', 'ⅩⅭ'];
    const ones = ['', 'Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', 'Ⅵ', 'Ⅶ', 'Ⅷ', 'Ⅸ'];

    let res = '';

    if (num <= 0) return res;

    if (num > 100000) {
        return '?';
    }

    while (num >= 1000) {
        res += 'M';
        num -= 1000;
    }

    if (num >= 100) {
        res += huns[Math.trunc(num / 100)];
        num %= 100;
    }

    if (num === 11) {
        res += 'Ⅺ';
        return res;
    }

    if (num === 12) {
        res += 'Ⅻ';
        return res;
    }

    if (num >= 10) {
        res += tens[Math.trunc(num / 10)];
        num %= 10;
    }

    res += ones[num];

    return res;
}
const Roman: IScript = {
    converter: toRoman,
    groups: ['Base 10', 'European'],
    uniqueSymbols: [1, 5, 10, 50, 100, 500, 1000],
};

const Róng: IScript = { ...Lepcha, cssClassName: 'Lepcha' };

const toSantali = toDec(['᱐', '᱑', '᱒', '᱓', '᱔', '᱕', '᱖', '᱗', '᱘', '᱙']);
const Santali: IScript = {
    converter: toSantali,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toSaurashtra = toDec([
    '꣐‎',
    '꣑‎',
    '꣒‎',
    '꣓‎',
    '꣔‎',
    '꣕‎',
    '꣖‎',
    '꣗‎',
    '꣘‎',
    '꣙',
]);
const Saurashtra: IScript = {
    converter: toSaurashtra,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toSharada = toDec(['𑇐', '𑇑', '𑇒', '𑇓', '𑇔', '𑇕', '𑇖', '𑇗', '𑇘', '𑇙']);
const Sharada: IScript = {
    converter: toSharada,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toSinhala = toDec(['෦', '෧', '෨', '෩', '෪', '෫', '෬', '෭', '෮', '෯']);
const Sinhala: IScript = {
    converter: toSinhala,
    groups: ['Base 10', 'Asian', 'Srilankan'],
    uniqueSymbols: decimalUniqueSymbols,
};

function toSinhalaIllakkam(num: number): string {
    const ones = ['', '𑇡', '𑇢', '𑇣', '𑇤', '𑇥', '𑇦', '𑇧', '𑇨', '𑇩'];
    const tens = ['', '𑇪', '𑇫', '𑇬', '𑇭', '𑇮', '𑇯', '𑇰', '𑇱', '𑇲'];
    const huns = ['', '𑇳', '𑇢𑇳', '𑇣𑇳', '𑇤𑇳', '𑇥𑇳', '𑇦𑇳', '𑇧𑇳', '𑇨𑇳', '𑇩𑇳'];

    //Ref : http://std.dkuug.dk/jtc1/sc2/wg2/docs/n3195.pdf

    let res = '';

    if (num <= 0) return res;

    if (num >= 10000) return '?';

    if (num >= 1000) {
        let i = Math.trunc(num / 1000);
        let prefix = ones[i <= 1 ? 0 : i];

        res = `${prefix}𑇴`;
        num %= 1000;
    }

    if (num >= 100) {
        res += huns[Math.trunc(num / 100)];
        num %= 100;
    }
    if (num >= 10) {
        res += tens[Math.trunc(num / 10)];
        num %= 10;
    }
    res += ones[num];

    return res;
}
const SinhalaIllakkam: IScript = {
    converter: toSinhalaIllakkam,
    groups: ['Base 10', 'Asian', 'Srilankan'],
    uniqueSymbols: [
        ...decimalUniqueSymbols.filter(d => d !== 0),
        ...decimalUniqueSymbols.filter(d => d !== 0).map(d => d * 10),
        100,
    ],
};

const toSorang = toDec(['𑃰', '𑃱', '𑃲', '𑃳', '𑃴', '𑃵', '𑃶', '𑃷', '𑃸', '𑃹']);
const SorangSompeng: IScript = {
    converter: toSorang,
    cssClassName: 'SorangSompeng',
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toSundanese = toDec(['᮰', '᮱', '᮲', '᮳', '᮴', '᮵', '᮶', '᮷', '᮸', '᮹']);
const Sundanese: IScript = {
    converter: toSundanese,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toTamil = toSouthIndian({
    basic: ['௦', '௧', '௨', '௩', '௪', '௫', '௬', '௭', '௮', '௯'],
    extra: ['', '', '௨', '௩', '௪', '௫', '௬', '௭', '௮', '௯'],
    tens: ['௰', '௱', '௲'],
});

const Tamil: IScript = {
    converter: toTamil,
    groups: ['Base 10', 'Asian', 'Indian', 'South Indian'],
    uniqueSymbols: [...decimalUniqueSymbols, 10, 100, 1000],
};

const toTelugu = toDec(['౦', '౧', '౨', '౩', '౪', '౫', '౬', '౭', '౮', '౯']);
const Telugu: IScript = {
    converter: toTelugu,
    groups: ['Base 10', 'Asian', 'Indian', 'South Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toThai = toDec(['๐', '๑', '๒', '๓', '๔', '๕', '๖', '๗', '๘', '๙']);
const Thai: IScript = {
    converter: toThai,
    groups: ['Base 10', 'Asian', 'Southeast Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toTibetan = toDec(['༠', '༡', '༢', '༣', '༤', '༥', '༦', '༧', '༨', '༩']);
const Tibetan: IScript = {
    converter: toTibetan,
    groups: ['Base 10', 'Asian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toTirhuta = toDec([
    '𑓐',
    '𑓑‎',
    '𑓒‎',
    '𑓓‎',
    '𑓔',
    '𑓕‎',
    '𑓖‎',
    '𑓗',
    '𑓘',
    '𑓙',
]);
const Tirhuta: IScript = {
    converter: toTirhuta,
    groups: ['Base 10', 'Asian', 'Indian'],
    uniqueSymbols: decimalUniqueSymbols,
};

const toUrdu = toDec([
    '٠',
    '١',
    '۲', //۲
    '۳',
    '۴',
    '۵',
    '٦',
    '۷',
    '٨',
    '٩',
]);
const Urdu: IScript = {
    converter: toUrdu,
    groups: ['Base 10', 'Asian', 'Indian', 'Eastern Arabic'],
    uniqueSymbols: decimalUniqueSymbols,
};

const Western: IScript = {
    converter: (num: number) => `${num}`,
    groups: ['Base 10', 'European'],
};

export const scripts: { [Key: string]: IScript } = {
    Aegean,
    Arabic,
    Balinise,
    Bengali,
    Brahmi,
    Braille,
    Burmese,
    Cham,
    Cistercian,
    Devanagari,
    Dzongkha,
    Egyptian,
    Gujarati,
    Gurmukhi,
    Japanese,
    Hebrew,
    Hindi,
    Kharoshti,
    Kannada,
    Khmer,
    Khudawadi,
    Khojki,
    Lao,
    Limbu,
    Malayalam,
    'Malayalam Old': MalayalamOld,
    Mangolian,
    Marathi,
    'Masaram Gondi': { ...MasaramGondi, cssClassName: 'MasaramGondi' },
    Mayan,
    Meitei,
    Mesopotamian,
    Minoan,
    Modi,
    Multani,
    Mycenaean,
    Nandinagari,
    Persian,
    Roman,
    Lepcha,
    'Ol Chiki': { ...Santali, cssClassName: 'Santali' },
    Róng,
    Saurashtra,
    Santali,
    Sharada,
    Sinhala,
    SinhalaIllakkam,
    SorangSompeng,
    Sundanese,
    Telugu,
    Thai,
    Tibetan,
    Urdu,
    Odia,
    Tamil,
    'Tirhuta Mithilakshar': { ...Tirhuta, cssClassName: 'Tirhuta' },
    Western,
};
