import { useEffect, useState } from 'react';

export function useTimer(interval: number = 1000) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        //Implementing the setInterval method
        const _interval = setInterval(() => {
            setCount(count + 1);
        }, interval);

        //Clear the interval
        return () => clearInterval(_interval);
    }, [count, interval]);

    return new Date();
}
