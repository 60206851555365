import { Stack } from '@mui/material';
import { Numeral } from 'features/numerals/Numeral';
import { useTimer } from 'hooks/utilityHooks';
import { allScriptNames } from './utils';
import { CommonProps } from 'components/common';

interface Props extends CommonProps {
    script: string;
}

export function ScriptClock({ script }: Props) {
    const date = useTimer();

    if (script == null || !allScriptNames.includes(script))
        return <h2>{`Error ! Unknown Script : "${script}"`}</h2>;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
        <>
            <Stack
                direction='row'
                sx={{ margin: '5px 10px 0px 5px' }}
                spacing={0}>
                <Digit
                    script={script}
                    num={hours}
                />
                <Digit
                    script={script}
                    num={minutes}
                />
                <Digit
                    script={script}
                    num={seconds}
                />
            </Stack>
            <Stack
                direction='row'
                sx={{ margin: '5px 10px 0px 5px' }}
                spacing={0}>
                <div style={contextStyle}>{hours}</div>
                <div style={contextStyle}>{minutes}</div>
                <div style={contextStyle}>{seconds}</div>
            </Stack>
        </>
    );
}

const contextStyle: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    fontWeight: 'normal',
    width: '33%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'center',
};

const _style: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    padding: '0px 0px 50px 0px',
    backgroundColor: 'black',
    fontSize: '9vw',
    fontWeight: 'normal',
    width: '33%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'magenta',
    verticalAlign: 'center',
    height: '150px',
};

function Digit({ script, num }: { script: string; num: number }) {
    const styleForScript: React.CSSProperties = {
        fontSize: [
            'Japanese',
            'Balinise',
            'Kharoshti',
            'Malayalam',
            'Malayalam Old',
            'Mesopotamian',
            'Roman',
            'Sinhala',
            'SinhalaIllakkam',
            'Tamil',
        ].includes(script)
            ? '9vw'
            : '12vw',
        fontWeight: script === 'Egyptian' ? 'bold' : 'normal',
    };

    const style = { ..._style, ...styleForScript };
    return (
        <div
            className={script}
            style={style}>
            <Numeral
                script={script}
                num={num}
                style={{ color: 'magenta' }}
            />
            <br />
        </div>
    );
}
