import React from 'react';

import { useShowHelp } from 'hooks/routerHooks';
import { Filler, GridToolbar } from 'components/Toolbar';

import { Help } from './SankhyaClockHelp';
import { useSetTitle } from 'hooks/appDataHooks';

import { ScriptClock } from 'features/numerals/ScriptClock';
import {
    DropdownForScriptOnPage,
    useScript,
} from 'features/numerals/DropdownForScriptOnPage';

export function SankhyaClock() {
    useSetTitle('Numeral System Clock');
    const showHelp = useShowHelp();
    const [script] = useScript();
    if (showHelp) return <Help />;
    return (
        <>
            <GridToolbar>
                <DropdownForScriptOnPage />
                <Filler />
            </GridToolbar>
            <ScriptClock script={script ?? 'Marathi'} />
        </>
    );
}
