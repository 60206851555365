import { CommonProps, Style } from 'components/common';
import React from 'react';
import { toNumber } from 'utils/toNumber';

const pathPoints = [
    '0,0 0,3', //For the central vertical line
    '0,0 1,0', // => 1
    '0,1 1,1', // => 2
    '0,0 1,1', //...
    '0,1 1,0',
    '0,0 1,0 0,1',
    '1,0 1,1',
    '0,0 1,0 1,1',
    '1,0 1,1 0,1',
    '0,0 1,0 1,1 0,1',
];

const transforms: { [key: number]: string } = {
    0: '',
    1: 'scale(-1, 1)', //Mirror
    2: 'translate(0, 3) scale(1, -1)',
    3: 'translate(0, 3) scale(-1, -1)',
};

function getPoints(digit: number, position: number) {
    const points = digit <= 9 ? pathPoints[digit] : pathPoints[0];
    const transform = transforms[position] ?? '';
    return { points, transform };
}

function digit(digit: number, position: number, style?: React.CSSProperties) {
    return (
        <polyline
            key={position}
            fill='none'
            stroke={style?.color ?? 'black'}
            strokeWidth={0.2}
            strokeLinecap='round'
            strokeLinejoin='round'
            {...getPoints(digit, position)}
        />
    );
}

interface Props extends CommonProps {
    num: number;
}

const _style: React.CSSProperties = {
    resize: 'both',
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    fontSize: 8,
};
export const Cistercian = ({ num, style = {} }: Props) => {
    if (num >= 10000) return <span>?</span>;

    const digits = `${num}`.split('').reverse().map(toNumber);
    return (
        <div style={Style(_style, style)}>
            <svg
                viewBox='-3 -2 6 6'
                style={{
                    width: '90%',
                    height: '90%',
                }}>
                {digit(0, 0, style)}
                {digits.map((d, i) => digit(d ?? 0, i, style))}
            </svg>
        </div>
    );
};
