import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import { useBooleanParam } from 'hooks/routerHooks';

export function ButtonPageSettings({
    disabled = false,
}: {
    disabled?: boolean;
}) {
    const [value, toggleValue] = useBooleanParam('show-settings', false);
    const color = disabled ? 'gray' : value ? 'green' : 'black';
    return (
        <IconButton
            onClick={toggleValue}
            disabled={disabled}>
            <SettingsIcon
                name='settings'
                sx={{ color, cursor: 'pointer' }}
            />
        </IconButton>
    );
}
