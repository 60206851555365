import React from 'react';
import { TextField } from '@mui/material';
import { useStringParam } from 'hooks/routerHooks';
import { CommonProps } from './common';

interface StringParamProps extends CommonProps {
    name: string;
    label: string;
    validator?: (_: string) => boolean;
}
export function TextInputForStringParam({
    name,
    label,
    validator = () => true,
    sx = {},
}: StringParamProps) {
    const [value, setValue] = useStringParam(name);

    return (
        <TextField
            sx={{ width: 140, ...sx }}
            label={label}
            variant='outlined'
            size='small'
            value={value ?? ''}
            onChange={e =>
                validator(e.target.value) && setValue(e.target.value)
            }
        />
    );
}
