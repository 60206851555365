import { CommonProps, BORDER, Style } from 'components/common';
import { Table } from './Table';
import React from 'react';

export function TableTabs({ selectedTab, pages, onChange }: Props) {
    const _selectedTab = Math.min(Math.max(selectedTab ?? 0, 0), pages.length);

    function getCell(r: number, c: number, key: string) {
        const borderBottom = _selectedTab === c ? '2px black solid' : BORDER;
        const backgroundColor = _selectedTab === c ? 'whitesmoke' : 'white';
        const width = `${(1 / pages.length) * 100}%`;

        const style = Style(_style, { borderBottom, width, backgroundColor });
        return (
            <td
                key={key}
                style={style}
                onClick={() => onChange(c)}>
                {pages[c].name}
            </td>
        );
    }

    return (
        <>
            <Table
                style={{
                    width: '100%',
                    border: 'none',
                    borderBottom: '1px rgb(220, 220, 220) solid',
                }}
                getCell={getCell}
                rows={1}
                columns={pages.length}
            />
        </>
    );
}

const _style: React.CSSProperties = {
    borderBottom: BORDER,
    borderLeft: BORDER,
    borderRight: BORDER,
    textAlign: 'center',
    cursor: 'pointer',
    padding: '5px 2px 5px 2px',
};

interface Props extends CommonProps {
    selectedTab?: number | null;
    pages: { name: React.ReactNode; node: React.ReactNode }[];
    onChange: (tab: number) => void;
}
