import { SxProps, Theme } from '@mui/material';
import React from 'react';

export interface CommonProps {
    style?: React.CSSProperties;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

export function OverFlow({ children }: { children: React.ReactNode }) {
    return <div style={{ overflowX: 'auto' }}>{children}</div>;
}

export const BORDER = '1px rgb(220, 220, 220) solid';

export function Style(
    x: React.CSSProperties,
    y?: React.CSSProperties
): React.CSSProperties {
    return y != null ? { ...x, ...y } : { ...x };
}
