import React from 'react';

import { Divider, Grid } from '@mui/material';
import { CommonProps } from 'components/common';

export function Filler() {
    return (
        <div
            style={{
                marginLeft: 'auto',
            }}
        />
    );
}

export function VerticalDivider() {
    return (
        <Divider
            orientation='vertical'
            flexItem
        />
    );
}

export function Toolbar({ children, style = {} }: CommonProps) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                columnGap: 5,
                ...style,
            }}>
            {children}
        </div>
    );
}

export function FlexGrid({ children }: CommonProps) {
    return (
        <Grid
            container
            spacing={0}
            sx={{
                flexGrow: 1,
                paddingLeft: '5px',
                paddingTop: '5px',
                paddingRight: '5px',
                marginBottom: '5px',
            }}>
            {children}
        </Grid>
    );
}

export function GridItem({ children }: CommonProps) {
    return (
        <Grid
            item
            xs>
            {children}
        </Grid>
    );
}

export function ToolbarGridItem({ children }: CommonProps) {
    return (
        <GridItem>
            <Toolbar>{children}</Toolbar>
        </GridItem>
    );
}

export function GridToolbar({ children }: CommonProps) {
    return (
        <FlexGrid>
            <ToolbarGridItem>{children}</ToolbarGridItem>
        </FlexGrid>
    );
}
