import { TextField } from '@mui/material';
import { useNumberParam } from 'hooks/routerHooks';
import { toNumber } from 'utils/toNumber';
import { CommonProps } from './common';

export function TextInputForNumberParam({
    name,
    label,
    sx = {},
}: NumberParamProps) {
    const [value, setValue] = useNumberParam(name);

    return (
        <TextField
            sx={{ width: 60, ...sx }}
            label={label}
            variant='outlined'
            size='small'
            value={value == null ? '' : value === 0 ? '' : `${value}`}
            onChange={e => setValue(toNumber(e.target.value))}
        />
    );
}

interface NumberParamProps extends CommonProps {
    name: string;
    label: string;
}
