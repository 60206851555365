import { InfoPanel, InfoText } from 'components/InfoText';
import { useSetTitle } from 'hooks/appDataHooks';

export function AboutPage() {
    useSetTitle('About Shankasur');
    return (
        <div>
            <InfoPanel reverse={true}>
                <table>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    fontSize: 100,
                                    color: 'whitesmoke',
                                    paddingLeft: '20px',
                                    paddingBottom: '20px',
                                }}>
                                <span
                                    className='Egyptian'
                                    style={{
                                        fontSize: 100,
                                    }}>
                                    𓀎
                                </span>
                            </td>
                            <td>
                                <InfoText reverse={true}>
                                    Shanka means doubt and Asur means Demon, the
                                    Demon of Doubts. "Doubt" that helps us
                                    fathom secrets of nature, "Doubt" that keeps
                                    modern science going. <br />
                                    <br />
                                </InfoText>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <InfoText reverse={true}>
                    I am a Software Developer by Profession and like to write
                    small apps for fun. You will find some here. All apps are
                    completely free, do not collect any data, and should work
                    offline as well. I will be happy if an app could arouse your
                    curiosity or if could use it as a teaching aid. If you find
                    a mistake in an app then I am all ears. Send me an email.
                    <br />
                    <br />
                    Besides software development, my other interests are
                    Mathematics, Physics, History, Geography, Music and My
                    Language (Marathi).
                    <br />
                    <br />
                    I'm an ardent fan of the scientific philosophy and
                    methodology invented in Europe. In my opinion it is the
                    second best thing that Europe has given us after Cricket. I
                    believe that the scientific temperament and methods are the
                    best ways to 'find the truth', and better our lives.
                    <br />
                    <br />
                </InfoText>
            </InfoPanel>
        </div>
    );
}
