import React from 'react';

import { CommonProps, Style } from 'components/common';

const _style: React.CSSProperties = {
    border: '1px rgb(220, 220, 220) solid',
    padding: '2px 2px 2px 2px',
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'relative',
    whiteSpace: 'nowrap',
};

interface Props extends CommonProps {
    script: string;
    num?: number;
    showWestern?: boolean;
}
export function TableCellForNumeral({
    script,
    num,
    showWestern = false,
    children,
}: Props) {
    const style =
        script === 'Egyptian' ? { fontSize: 'large', color: 'black' } : {};

    return (
        <td
            style={Style(_style, style)}
            key={num}>
            {children}
        </td>
    );
}

export function WesternNumeralInCell({ num }: { num: number | undefined }) {
    return (
        <React.Fragment>
            <br />
            <span style={{ color: 'blue', fontSize: 12 }}>
                {Intl.NumberFormat('en-US').format(num ?? 0)}
            </span>
        </React.Fragment>
    );
}
