import React from 'react';
import { OverFlow, CommonProps } from 'components/common';

export function Table({ rows, columns, getCell, style = {} }: Props) {
    const Row = (_: number, r: number) => (
        <tr key={r}>
            {Array(columns)
                .fill(1)
                .map((_, c) => getCell(r, c, `${r}-${c}`))}
        </tr>
    );

    const Rows = () => (
        <React.Fragment>{Array(rows).fill(1).map(Row)}</React.Fragment>
    );

    return (
        <OverFlow>
            <table style={{ ..._style, ...style }}>
                <tbody>
                    <Rows />
                </tbody>
            </table>
        </OverFlow>
    );
}

interface Props extends CommonProps {
    rows: number;
    columns: number;
    getCell: (r: number, c: number, key: string) => React.ReactNode;
}

const _style: React.CSSProperties = {
    border: '2px rgb(220, 220, 220) solid',
    borderCollapse: 'collapse',
};
