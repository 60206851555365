import { CommonProps } from 'components/common';

export function Select({
    value,
    onChange = () => {},
    style = {},
    children,
}: Props) {
    const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
        onChange(e.target.value);

    return (
        <select
            style={style}
            value={value}
            onChange={_onChange}>
            {children}
        </select>
    );
}

interface Props extends CommonProps {
    onChange?: (newValue: string) => void;
    value?: string;
}
