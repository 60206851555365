import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';

import { ButtonPageSettings } from 'components/ButtonPageSettings';
import { ButtonsPageNavigation } from 'components/ButtonsPageNavigation';
import { TableTabs } from 'components/TableTabs';
import { TextInputForNumberParam } from 'components/TextInputForNumberParam';
import { TextInputForStringParam } from 'components/TextInputForStringParam';
import { Filler, GridToolbar, VerticalDivider } from 'components/Toolbar';
import { CommonProps } from 'components/common';

import {
    DropdownForScriptOnPage,
    useScript,
} from 'features/numerals/DropdownForScriptOnPage';
import { NumeralInfo } from 'features/numerals/NumeralInfo';
import { ScriptBaseMultiples } from 'features/numerals/ScriptBaseMultiples';
import { ScriptClock } from 'features/numerals/ScriptClock';
import { TableOfNumerals } from 'features/numerals/TableOfNumerals';
import { allScriptNames } from 'features/numerals/utils';

import { useSetTitle } from 'hooks/appDataHooks';
import {
    useBooleanParam,
    usePageParam,
    useShowHelp,
    useStringParam,
} from 'hooks/routerHooks';

import { generate2DSequence, limit } from 'utils/maths';
import { toNumber } from 'utils/toNumber';

import { Help } from './SankhyaHelp';

export function Sankhya() {
    useSetTitle('Numeral Systems');
    const showHelp = useShowHelp();
    if (showHelp) return <Help />;
    return (
        <>
            <ToolbarForPage />
            <TabPageTitles />
            <TabPage />
        </>
    );
}

const pages: { name: React.ReactNode; node: React.ReactNode }[] = [
    { name: 'Numerals', node: <PanelNumerals /> },
    {
        name: <PanelNumeralInfoTitle />,
        node: <PanelNumeralInfo />,
    },
    {
        name: <PanelBaseMultiplesTitle />,
        node: <PanelBaseMultiples />,
    },
    {
        name: <PanelScriptClockTitle />,
        node: <PanelScriptClock />,
    },
];

function useView(): [number, (_: number) => void] {
    const [view, setView] = useStringParam('view');

    const views = [undefined, 'info', 'base', 'clock'];

    const setViewId = (value: number) => setView(views[value]);

    const _viewId =
        view == null ? 0 : views.findIndex(x => view.toLowerCase() === x) || 0;

    const viewId = limit(_viewId ?? 0, 0, pages.length);

    return [viewId, setViewId];
}

function ButtonShowInfo() {
    const [showInfo, toggleShowInfo] = useBooleanParam('show-info', false);
    return (
        <IconButton onClick={toggleShowInfo}>
            <InfoIcon
                sx={{
                    color: showInfo ? 'green' : 'black',
                    cursor: 'pointer',
                }}
            />
        </IconButton>
    );
}

function ButtonShowWesternNumerals() {
    const [value, onClick] = useBooleanParam('show-western', false);
    const [viewId] = useView();

    const enabled = [0, 2].includes(viewId);

    const sx = {
        color: !enabled ? 'grey' : value ? 'green' : 'black',
        cursor: 'pointer',
    };

    if (value)
        return (
            <IconButton
                onClick={onClick}
                disabled={!enabled}>
                <VisibilityOffIcon sx={sx} />
            </IconButton>
        );
    return (
        <IconButton
            onClick={onClick}
            disabled={!enabled}>
            <VisibilityIcon sx={sx} />
        </IconButton>
    );
}

function ButtonPageSettingsEx() {
    const [viewId] = useView();
    const enabled = viewId === 0;
    return <ButtonPageSettings disabled={!enabled} />;
}

function ToolbarForPage() {
    return (
        <GridToolbar>
            <DropdownForScriptOnPage />
            <Filler />
            <VerticalDivider />
            <ButtonShowInfo />
            <ButtonShowWesternNumerals />
            <ButtonPageSettingsEx />
        </GridToolbar>
    );
}

function TabPageTitles() {
    const [showInfo] = useBooleanParam('show-info', false);
    const [viewId, setViewId] = useView();
    const _selected = !showInfo ? 0 : viewId;
    const tabProps = { selectedTab: _selected, onChange: setViewId, pages };
    return showInfo ? <TableTabs {...tabProps} /> : null;
}

function TabPage() {
    const [viewId] = useView();
    const [showInfo] = useBooleanParam('show-info', false);
    const _selected = !showInfo ? 0 : viewId;

    return <>{pages[_selected].node}</>;
}

export function TextInputForSequenceParam({ sx = {} }: CommonProps) {
    return (
        <TextInputForStringParam
            name='sequence'
            label='Sequence'
            sx={{ width: '50%', ...sx }}
        />
    );
}

export function TextInputForRowsParam() {
    return (
        <TextInputForNumberParam
            name='rows'
            label='Rows'
            sx={{ width: '25%' }}
        />
    );
}

export function TextInputForColumnsParam() {
    return (
        <TextInputForNumberParam
            name='columns'
            label='Columns'
            sx={{ width: '25%' }}
        />
    );
}

function ToolbarForPageSettings() {
    const [showSettings] = useBooleanParam('show-settings', false);
    if (!showSettings) return null;
    return (
        <GridToolbar>
            <TextInputForSequenceParam />
            <TextInputForRowsParam />
            <TextInputForColumnsParam />
        </GridToolbar>
    );
}

function PanelNumerals() {
    return (
        <>
            <ToolbarForPageSettings />
            <TableNumerals />
        </>
    );
}

function PanelNumeralInfoTitle() {
    return (
        <span
            className='Symbol'
            style={{
                color: 'green',
            }}>
            ?
        </span>
    );
}

function PanelNumeralInfo() {
    const [script] = useScript();
    if (script == null) return null;

    return (
        <div style={{ margin: '30px 5px 0px 10px' }}>
            <NumeralInfo script={script} />
        </div>
    );
}

function PanelBaseMultiplesTitle() {
    return (
        <>
            <span style={{ color: 'magenta', fontSize: 18 }}>10</span>
            <sup style={{ color: 'magenta' }}>x</sup>
        </>
    );
}

function PanelBaseMultiples() {
    const [script] = useScript();
    const [value] = useBooleanParam('show-western', false);

    if (script == null) return null;
    return (
        <div style={{ margin: '30px 5px 0px 10px' }}>
            <ScriptBaseMultiples
                script={script}
                multiples={6}
                style={{
                    width: '100%',
                    border: '1px rgb(220, 220, 220) solid',
                }}
                showWestern={value}
            />
        </div>
    );
}

function PanelScriptClockTitle() {
    return (
        <span
            className='Symbol'
            style={{
                fontSize: 16,
                color: 'blue',
            }}>
            ⏱
        </span>
    );
}

function PanelScriptClock() {
    const [script] = useScript();
    if (script == null) return null;
    return (
        <div style={{ margin: '30px 5px 0px 10px' }}>
            <ScriptClock script={script} />
        </div>
    );
}

function TableNumeralSummary({ numbers }: { numbers: number[][] }) {
    const _numbers = `${numbers[0][0]} - ${
        numbers[numbers.length - 1][numbers[0].length - 1]
    }`;

    const message = `${_numbers}`;

    return (
        <div
            style={{
                margin: '0px 0px 0px 0px',
                padding: '0px 0px 0px 5px',
                textAlign: 'center',

                color: 'black',
            }}>
            <span>{message}</span>
        </div>
    );
}

function TableNumerals() {
    const [params] = useSearchParams();
    const { page, goToPreviousPage, goToNextPage } = usePageParam();

    const script = params.get('script') ?? 'Marathi';
    const sequence = params.get('sequence') ?? '';
    const rows = toNumber(params.get('rows')) ?? 10;
    const columns = toNumber(params.get('columns')) ?? 10;
    const numbers = generate2DSequence(sequence, page, rows, columns);
    const showWestern = (params.get('show-western') ?? '0') === '1';

    const handlers = useSwipeable({
        onSwipedRight: () => goToPreviousPage(),
        onSwipedLeft: () => goToNextPage(),
        swipeDuration: 250,
    });

    if (!allScriptNames.includes(script ?? ''))
        return <h2>{`Error ! Script not found : "${script}"`}</h2>;

    return (
        <React.Fragment>
            <GridToolbar>
                <TableNumeralSummary numbers={numbers} />
                <Filler />
                <VerticalDivider />
                <ButtonsPageNavigation />
            </GridToolbar>

            <div {...handlers}>
                <TableOfNumerals
                    script={script}
                    numbers={numbers}
                    showWestern={showWestern}
                />
            </div>
        </React.Fragment>
    );
}
