import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Chip } from '@mui/material';
import { Box } from '@mui/material';
import { getQuiquesymbols, getScriptGroups } from 'features/numerals/utils';
import { CommonProps } from 'components/common';
import { Numeral } from './Numeral';

export function NumeralInfo({ script }: { script: string }) {
    return (
        <div>
            <RelatedScripts script={script} />
            <UniqueSymbols script={script} />
        </div>
    );
}
/*
const styles: { [key: string]: React.CSSProperties } = {
    'Default': { backgroundColor: 'orange', color: 'black' },
    'Base 10': { backgroundColor: 'aqua', color: 'black' },
    'European': { backgroundColor: 'blue' },
    'Aegean': { backgroundColor: 'blue' },
    'Greek': { backgroundColor: 'blue' },
    'Ancient': { backgroundColor: 'gold', color: 'blue' },
};*/

function NumeralSystemGroupLinks({ script }: { script: string }) {
    const navigate = useNavigate();

    const links = getScriptGroups(script ?? '').map(({ name, scripts }) => (
        <Chip
            key={`${name}`}
            color='primary'
            label={name}
            onClick={() => navigate(`/sankhya-sbs?scripts=${scripts}`)}
            sx={{ minWidth: 100 }}
        />
    ));

    return <React.Fragment>{links}</React.Fragment>;
}

function RelatedScripts({ script }: { script: string }) {
    return (
        <div>
            Related Scripts
            <FlexBox>
                <NumeralSystemGroupLinks script={script} />
            </FlexBox>
        </div>
    );
}

function UniqueSymbols({ script }: { script: string }) {
    const uniqueNumbers = getQuiquesymbols(script);
    if (uniqueNumbers == null) return null;
    const numerals = uniqueNumbers.map((num, key) => (
        <div
            key={key}
            style={{
                backgroundColor: 'aqua',
                textAlign: 'center',
            }}>
            <div
                style={{
                    borderBottom: '1px lightgray solid',
                    minWidth: '40px',
                    minHeight: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {num}
            </div>
            <div
                style={{
                    borderBottom: '1px lightgray solid',
                    minWidth: 'Egyptian' ? '70px' : '40px',
                    height: script === 'Egyptian' ? '70px' : '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Numeral
                    script={script}
                    num={num}
                    key={num}
                    style={{ height: '40px' }}
                />
            </div>
        </div>
    ));
    return (
        <div>
            Unique Symbols
            <FlexBox>{numerals}</FlexBox>
        </div>
    );
}

function FlexBox({ children }: CommonProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                m: 1,
                p: 1,
                bgcolor: '#fff',
                color: 'black',
                columnGap: '3px',
                flexWrap: 'wrap',
                rowGap: 1,
            }}>
            {children}
        </Box>
    );
}
